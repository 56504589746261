import decodeJwt, { JwtPayload } from 'jwt-decode';

interface windowWithAuthProviders extends Window {
    firebase?: any;
    oktaClient?: any;
  }
  
declare const window: windowWithAuthProviders;

export const getIdToken = async (auth) => {
    if (window.oktaClient && await window.oktaClient.isAuthenticated()) {
        return window.oktaClient.getAccessToken()
    }
    else if (window.firebase) {
        const currentUser = window.firebase.auth().currentUser;
        return currentUser
            ? currentUser.getIdToken()
            : null
        ;
    }
    else if (auth) {
        return auth.idToken || null;
    }
    else {
        return null;
    }
};

export const refreshAuth = async ({ apiKey, refreshToken }) => {
    const url = 'https://securetoken.googleapis.com/v1/token?key=' + encodeURIComponent(apiKey);
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'grant_type=refresh_token&refresh_token=' + encodeURIComponent(refreshToken),
    });
    const data = await res.json();

    return {
        idToken: data.id_token,
        refreshToken: data.refresh_token,
    };
};

export const refreshAuthTimeout = ({ auth, config, setAuth }) => {
    let timeout;
    if (auth && auth.idToken && !window.firebase) {
        try {
            const decodedJwt: JwtPayload = decodeJwt(auth.idToken);
            const expiration = decodedJwt.exp;

            if (expiration) {
                timeout = setTimeout(async () => {
                    const newAuth = await refreshAuth({
                        apiKey: config.FIREBASE_API_KEY,
                        refreshToken: auth.refreshToken
                    });
                    setAuth(newAuth);
                }, ((expiration - 60) * 1000) - Date.now());
            }
        }
        catch (e) {
            console.error('Could not parse token: ' + e);
        }
    }

    return timeout;
};