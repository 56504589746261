import isMatch from 'lodash.ismatch';

type NavigateData = {
    type: 'mfe-navigate',
    url: string,
};

type MountData = {
    type: 'mfe-mount',
    name: string,
    contentId: string,
    params: any,
};

type UnmountData = {
    type: 'mfe-unmount',
    name: string,
    contentId: string,
};

type HandlerData = NavigateData | MountData | UnmountData;

type Handler = (data: HandlerData) => void;

const _registerHandler = (match, handler: Handler): () => void => {
    const listener = ({ data: jsonData }) => {
        let data = undefined;
        try {
            data = JSON.parse(jsonData);
        }
        catch (e) {}
        if (data && typeof data === 'object' && isMatch(data, match)) {
            handler(data);
        }
    };
    window.addEventListener('message', listener);

    return () => {
        window.removeEventListener('message', listener);
    };
};

export const registerNavigationHandler = (handler: Handler) => _registerHandler({ type: 'mfe-navigate' }, handler);
export const registerMountHandler = (name: string, handler: Handler) => _registerHandler({ type: 'mfe-mount', name }, handler);
export const registerUnmountHandler = (name: string, handler: Handler) => _registerHandler({ type: 'mfe-unmount', name }, handler);

const _postMessage = (targetWindow: Window, data: HandlerData) => targetWindow.postMessage(JSON.stringify(data), '*');

export const navigate = (targetWindow: Window, url: string) => {
    _postMessage(targetWindow, { type: 'mfe-navigate', url });
};
export const mount = (targetWindow: Window, name: string, contentId: string, params = {}) => {
    _postMessage(targetWindow, { type: 'mfe-mount', name, contentId, params: params || {} });
};
export const unmount = (targetWindow: Window, name: string, contentId: string) => {
    _postMessage(targetWindow, { type: 'mfe-unmount', name, contentId });
};